import { useEffect } from 'react'

import { useUser } from '@clerk/remix'
import * as Sentry from '@sentry/remix'

/**
 * Custom hook to initialize LogRocket for user monitoring.
 *
 * Client only.
 */
export function useUserMonitoring() {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`
                             .
                            | \\/|
    (\\   _                  ) )|/|
        (/            _----. /.'.'
  .-._________..      .' @ _\\  .'
  '.._______.   '.   /    (_| .')
    '._____.  /   '-/      | _.'      S C R E E C H !
     '.______ (         ) ) \\
       '..____ '._       )  )
          .' __.--\\  , ,  // ((
          '.'  mrf|  \\/   (_.'(
                  '   \\ .'
                   \\   (
                    \\   '.
                     \\ \\ '.)
                      '-'-'

          L o r i k e e t . A I`)
    if (import.meta.env.PROD) {
      void import('logrocket').then(({ default: LogRocket }) => {
        LogRocket.init('viltrr/optech-app')
      })
    }
  }, [])
}

const LORIKEET_USER_TOKEN_KEY = 'lori_user_token'

/**
 * Client only.
 */
export const useIdentifyUser = () => {
  const { user, isSignedIn } = useUser()

  useEffect(() => {
    if (!user) {
      return
    }

    if (!isSignedIn) {
      return
    }

    const { id, firstName, lastName, emailAddresses } = user
    const emailResource = emailAddresses[0] || {}

    if (import.meta.env.PROD) {
      void import('logrocket').then(({ default: LogRocket }) => {
        LogRocket.identify(id, {
          firstName: String(firstName),
          lastName: String(lastName),
          email: emailResource.emailAddress,
        })
      })
    }
    Sentry.setUser({
      id,
      firstName: String(firstName),
      lastName: String(lastName),
      email: emailResource.emailAddress,
    })
  }, [user, isSignedIn])

  useEffect(() => {
    if (!isSignedIn) {
      return
    }

    if (!window.lorikeet) {
      return
    }

    const cachedToken = sessionStorage.getItem(LORIKEET_USER_TOKEN_KEY)
    if (cachedToken) {
      window.lorikeet.init({
        token: cachedToken,
      })
      return
    }

    void fetch('/api/token')
      .then((response) => {
        if (response.ok) {
          return response.text()
        }
        return null
      })
      .then((token) => {
        if (token) {
          sessionStorage.setItem(LORIKEET_USER_TOKEN_KEY, token)
          window.lorikeet.init({
            token,
          })
        }
      })
  }, [isSignedIn])
}
